/*@import 'device.css';
@import 'tabs.css';*/

/*body {
  min-block-size: 100%;
  min-inline-size: 100%;
  margin: 0;
  box-sizing: border-box;
  display: grid;
  place-content: center;
  font-family: system-ui, sans-serif;
}
p {
  max-inline-size: 35ch;
  margin-block: 0.5ch;
}
p + p {
  margin-block-start: 1rem;
}
h2 {
  font-size: 2.5rem;
  margin-block-end: 0.5rem;
  line-height: 1;
}
h2:first-of-type {
  margin-block-start: 0;
}

article {
  box-sizing: border-box;
  font-family: 'Blokk';
  font-size: 1.25rem;
  line-height: 1;
  color: #000;
}*/

snap-tabs {
  --hue: 328deg;
  --accent: var(--hue) 100% 54%;
  --indicator-size: 2px;
  --space-1: 0.5rem;
  --space-2: 1rem;
  --space-3: 1.5rem;
  display: flex;
  flex-direction: column;
  overflow: overlay;
  position: relative;
  flex-grow: 1;
  @apply filter;
  @apply drop-shadow-2xl;
}
snap-tabs self:matches(header, nav, section, article, a) {
  outline-color: #000;
  outline-offset: -5px;
}
.scroll-snap-x {
  overflow: auto hidden;
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;
}
@media (prefers-reduced-motion: no-preference) {
  .scroll-snap-x {
    scroll-behavior: smooth;
  }
}
@media (hover: none) {
  .scroll-snap-x {
    scrollbar-width: none;
  }
  .scroll-snap-x::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
snap-tabs > header {
  --text-color: #000;
  --text-active-color: #000;
  flex-shrink: 0;
  min-block-size: fit-content;
  display: flex;
  flex-direction: column;
}
snap-tabs > header > nav {
  display: flex;
  justify-content: space-evenly;
}
snap-tabs > header a {
  scroll-snap-align: start;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  font-size: 0.8rem;
  color: var(--text-color);
  font-weight: bold;
  text-decoration: none;
  padding: var(--space-2) var(--space-3);
  -webkit-tap-highlight-color: transparent;
}
snap-tabs > header a:focus {
  outline-offset: -0.5ch;
}
snap-tabs > header > .snap-indicator {
  inline-size: 0;
  block-size: var(--indicator-size);
  border-radius: var(--indicator-size);
  background: #000;
}
snap-tabs > section {
  block-size: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 100%;
}
snap-tabs > section > article {
  scroll-snap-align: start;
  /*overflow-y: auto;
  overscroll-behavior-y: contain;
  padding: var(--space-2) var(--space-3);*/
}
@media (prefers-reduced-motion: reduce) {
  /* - swap to border-bottom styles - transition colors - hide the animated .indicator */
  snap-tabs > header a {
    border-block-end: var(--indicator-size) solid #000;
    transition: color 0.7s ease, border-color 0.5s ease;
  }
  snap-tabs > header a self:matches(:target, :active, [active]) {
    color: var(--text-active-color);
    border-block-end-color: #000;
  }
  snap-tabs .snap-indicator {
    visibility: hidden;
  }
}

@keyframes octocat-wave {
  0%,
  100% {
    transform: rotate(0);
  }
  20%,
  60% {
    transform: rotate(-25deg);
  }
  40%,
  80% {
    transform: rotate(10deg);
  }
}

/*ADDED raul*/

.verlassen {
  color: #fff;
  font-size: 2rem;
  padding-top: 2rem;
  padding-left: 4rem;
}
.container-header {
  display: block;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.container-nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 10rem;
  gap: 5rem;
  @apply px-28;
}

.nav-items {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  width: 10rem;
  flex-grow: 1;
}

.item-line {
  width: 100%;
  height: 1rem;
  background-color: rgb(218, 218, 218);
  border-radius: 16px;
  overflow: hidden;
}

.item-line .progress {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 10px;
  transform: scale3d(0, 1, 1);
  transform-origin: left;
}

.header-snap-tabs {
  width: 100%;
}

.section-snap-tabs {
  z-index: 1;
  width: 100%;
  position: relative;
}

.item-snap-tabs {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

.back-touch {
  display: grid;
  align-items: flex-end;
  -webkit-tap-highlight-color: transparent;
  color: #fff;
  grid-area: 1 / 1 / 2 / 2;
}

.forward-touch {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-column-start: 4;
  grid-row-start: 1;
  -webkit-tap-highlight-color: transparent;
  color: #fff;
}

.icon-left {
  display: flex;
  justify-content: center;
  align-self: center;
  margin-right: 20rem;
  font-size: 100px;
}

.icon-right {
  display: flex;
  justify-content: center;
  margin-right: 6rem;
  margin-left: 6rem;
  font-size: 100px;
}

.icon-touch {
  font-size: 100px;
}

.img-snap-tabs {
  grid-area: 1 / 1 / 2 / 5;
  overflow: hidden;
}

@keyframes progres {
  0% {
    width: 0%;
  }
  25% {
    width: 25%;
  }
  50% {
    width: 50%;
  }
  75% {
    width: 75%;
  }
  100% {
    width: 100%;
  }
}

.slider-text-container {
  position: absolute;
  left: 0;
  bottom: 8rem;
  width: auto;
  @apply text-base;
  @apply text-white;
}

.slider-title {
  font-size: 50px;
  line-height: 0.8;
}

.slider-text {
  font-size: 150px;
  line-height: 1;
}

.footer-logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.alle-themen {
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.plus-button {
  display: flex;
  justify-content: center;
  transform: translateY(-3rem);
}

.plus-icon {
  height: 40%;
  width: auto;
}

.portfolio {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
}
