.contact-container {
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-template-rows: min-content min-content min-content min-content;
  gap: 0px 0px;
  grid-template-areas:
    'Name Image'
    'Bubble Image'
    'Contact QR';
  gap: 0px 40px;
}
.contact-name {
  grid-area: Name;
}
.conctact-contact {
  grid-area: Contact;
}
.contact-qr {
  grid-area: QR;
}
.contact-bubble {
  grid-area: Bubble;
}
.contact-image {
  grid-area: Image;
}
