.page-grid-template {
  grid-template-rows: min-content 1fr min-content;
}
.backdrop-brightness-20 {
  --tw-backdrop-brightness: brightness(0.2);
}
.page article p {
  width: 800px;
  font-size: var(--font-size-xs);
  line-height: var(--line-height-xs);
}
.page article p + h2 {
  margin-top: 60px;
  max-width: 100%;
}

.page .main-grid-template {
  grid-template-columns: auto 1fr;
}

.pl-lefty {
  padding-left: 100%;
}

.pl-lefty-one {
  padding-left: 40px;
}

.w-test {
  width: 80rem;
}

.w-test-one {
  width: 50vw;
}

.pl-test-left {
  padding-left: 45rem;
}

.max-w-unset {
  max-width: unset;
}

.page-gallery-grid-template {
  /*grid-template-rows: min-content 1fr 200px 350px min-content;*/
  display: flex;
  flex-direction: column;
}

/*margin-top: calc((56px - 16px) / 2),
where 56px - parent height, 16px - element height/font-size*/

.h-gallery {
  /*height: 100rem;*/
  height: 90rem;
}

.ar-16-9 {
  aspect-ratio: 16 / 9;
}

.page-video-grid-template {
  grid-template-rows: 1fr min-content;
}

.page-video-object-fit > video {
  object-fit: cover;
}

.page .progress {
  appearance: none;
}

input[type='range'] {
  -webkit-appearance: none;
  margin: 18px 0;
  width: 100%;
}
input[type='range']:focus {
  outline: none;
}
input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 3px;
  cursor: pointer;

  background: #48484844;
  border: 0px solid #010101;
  border-radius: 10px;
}
input[type='range']::-webkit-slider-thumb {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -30px;
  border: 0;
}
input[type='range']:focus::-webkit-slider-runnable-track {
  background: #367ebd;
}

.remove-chrome-outline:active,
.remove-chrome-outline:focus,
.remove-chrome-outline:focus * {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  outline: 0;
}

@keyframes flickerAnimation {
  0% {
    transform: translateY(100rem);
  }
  100% {
    transform: translateY(0);
  }
}

.test {
  -webkit-animation: flickerAnimation 0.25s;
  -moz-animation: flickerAnimation 0.25s;
  -o-animation: flickerAnimation 0.25s;
  animation: flickerAnimation 0.25s;
}

@keyframes flickerAnimation-out {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100rem);
  }
}

.test-out {
  -webkit-animation: flickerAnimation-out 0.25s;
  -moz-animation: flickerAnimation-out 0.25s;
  -o-animation: flickerAnimation-out 0.25s;
  animation: flickerAnimation-out 0.25s;
}
