a {
  -webkit-tap-highlight-color: transparent;
}

html {
  overflow: scroll;
}
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  --tw-bg-opacity: 1;
  background-color: rgba(242, 242, 242, var(--tw-bg-opacity));
}

/* THEMEN */
.themen-appear,
.themen-enter {
  opacity: 0;
  z-index: 1;
  transform: translateX(-200rem);
}

.themen-appear-active,
.themen-enter.themen-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: transform 300ms ease-out 150ms;
}

/* STORY */
.story-appear,
.story-enter {
  opacity: 0;
  z-index: 1;
  transform: translateY(-100rem);
}

.story-appear-active,
.story-enter.story-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: transform 300ms ease-out 150ms;
}

/* PORTFOLIO */
.portfolio-appear,
.portfolio-enter {
  opacity: 0;
  z-index: 1;
  transform: translateX(200rem);
}

.portfolio-appear-active,
.portfolio-enter.portfolio-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: transform 300ms ease-out 150ms;
}

.portfolio-exit {
  transform: translateX(0);
}

.portfolio-exit.portfolio-exit-active {
  transform: translateX(-10px);
  transition: transform 150ms ease-in;
}

/* PAGE */
/*.page-appear,
.page-enter {
  opacity: 0;
  z-index: 1;
  transform: translateY(100rem);
}

.page-appear-active,
.page-enter.page-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: transform 300ms ease-in 150ms;
}*/

.page-appear,
.page-enter {
  opacity: 0;
  z-index: 1;
  transform: translateY(100rem);
}

.page-appear-active,
.page-enter.page-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: transform 300ms ease-in 150ms;
}
