@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --font-size-xs2: 1rem;
  --font-size-xs: 1.25rem;
  --font-size-sm: 1.5625rem;
  --font-size-base: 2.1875rem;
  --font-size-ab: 2.5rem;
  --font-size-md: 3.125rem;
  --font-size-lg: 4.375rem;
  --font-size-xl: 6.25rem;
  --line-height-xs: 1.6875rem;
  --line-height-sm: 2.1875rem;
  --line-height-base: 2.9375rem;
  --line-height-ab: 1;
  --line-height-md: 4.3125rem;
  --line-height-lg: 4.375rem;
  --line-height-xl: 7rem;
}
body {
  font-family: 'Nunito Sans';
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
}

h1 {
  font-size: var(--font-size-xl);
  line-height: var(--line-height-xl);
  font-weight: 800;
}

h2 {
  font-size: var(--font-size-md);
  line-height: var(--line-height-md);
  font-weight: 800;
  margin-bottom: 32px;
}

h3 {
  font-size: var(--font-size-sm);
  line-height: var(--line-height-md);
  font-weight: 800;
  margin-bottom: 32px;
}

p {
  font-size: var(--font-size-xs2);
  line-height: var(--line-height-xs);
  margin-bottom: 32px;
}

.smooth-scroll {
  scroll-behavior: smooth;
}
